a {
  color: #0366d6;
}

code {
  color: #E01A76;
}

body { 
  overflow-x: hidden;
}

.btn-primary {
  color: #fff;
  background-color: #1b6ec2;
  border-color: #1861ac;
}

.text-justify {
  text-align: justify;
  text-justify: inter-word;
}

.breadcrumb {
    background-color: #FAFAFA;
    margin: 0px;
    padding-top: 2px;
    padding-bottom: 0px;
    padding-left: 10px;
}

/*.dropdown {
  border-radius: 0 !important;
}*/

.dropdownmenu {
  overflow-y: scroll;
}