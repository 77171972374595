a.navbar-brand {
  white-space: normal;
  text-align: center;
  word-break: break-all;
  padding-top: 1px;
  padding-bottom: 1px;
}

.navbar {
  font-size: 18px;
}

.box-shadow {
  box-shadow: 0 .25rem .75rem rgba(0, 0, 0, .05);
}

.header-border {
  border-bottom: 5px solid #D6EAF8;
}

.navlink {
  text-decoration: none;
  color: #000000;
}

.navlink:hover {
    text-decoration: underline;
    color: #000000;
}

.navlinkactive {
  font-weight: bold;
  text-decoration: none;
  color: #92C7ED !important;
}

