@media (min-width: 768px) {
    html {
        font-size: 14px;
    }
}

body {

}

.mainDiv {
    max-height: 100%;
    min-height: calc(100vh - 16px);
    height: auto;
    width: 768px;
    max-width: 100%;
    border-radius: 20px;
    background-color: #FFFFFF;
    margin: 8px;
}

.backgroundDiv {
    background-color: #D6EAF8;
    overflow: auto;
    display: flex;
    justify-content: center;
}

.homeBoxHeader {
    height: 40px;
    font-size: 14px;
    display: flex;
    align-items: center;
    justify-content: center;
}
    
div.helpIcon {
    position: fixed;
    right: 20px
}
.MuiTabs-root {
    height: 34px;
}

.border-3 {
    border-width: 3px !important;
}

.commonHeading {
    margin-left: 20px;
    font-size: 12px;
    font-weight: bold;
    background-color: white;
}

.commonHeadingMorePadding {
    margin-left: 20px;
    font-size: 12px;
    font-weight: bold;
    background-color: white;
    padding-top: 10px;
}

.gridStyle {
    height: 400px;
    background-color: white;
    margin-top: 20px;
    margin-bottom: 10px;
    margin-left: 10px;
}

.redColumnText {
    color: red;
}

.greenColumnText {
    color: Green;
}

/* Hide the up and down arrows in MUI Input */
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

input[type="number"] {
  appearance: textfield;
}

.modal-header {
    border-color: #99C2FF;
    border-width: 3px;
}

.imagePreview {
    max-height: 200px;
    max-width: 50%;
    cursor: zoom-in;
}

.borderTop {
    border-top: 3px solid #81BEF7;
}

.fab {
    display: flex;
    position: fixed;
    width: 768px;
    max-width: 100%;
    justify-content: center;
    bottom: 2vh;
}

.fullScreenImage {
    max-width: 100%;
    max-height: 100%;
    height: auto;
    width: auto\9;
    cursor: zoom-out;
}

.dataGridPriceCell {
    font-weight: bold;
}